import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { postData } from "../../helpers/request";
import {
  ApiContext,
  ToolTipError,
  ERROR_REGEX,
  VisitorAPI,
} from "../../index.js";
import { FaEye } from "react-icons/fa";
import { RetryErrorContext, SignupDataContext } from "../ClientComponents";
import toast from "react-hot-toast";
// import { useLocation } from "react-router-dom";
import company_code_data from "../../archive/test_data/company_code.json";
import { useSelector } from "react-redux";
import redirectionEnum from "../../utils/redirectionEnum.js";
//Error components
const InputError = (props) => {
  return (
    <div className="client-login-input-error password-error">
      {props.errorMsg}
    </div>
  );
};

const ClientSignupForm = () => {
  const API_URL = useContext(ApiContext);
  const history = useNavigate();
  const { setSignupData } = useContext(SignupDataContext);
  // Using ref hook to capture user credentials
  const loggedClientEmail = useRef(null);
  const loggedClientPassword = useRef(null);
  const loggedClientConfirmPassword = useRef(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confrimPasswordError, setConfirmPasswordError] = useState(null);
  const { setRetryError } = useContext(RetryErrorContext);
  const [signUpDisableBtn, setSignUpDisableBtn] = useState(false);
  const { comp_code } = useSelector((state) => state.profileData);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setRetryError(false);
    VisitorAPI();
  }, []);

  const { company_code } = useParams();
  const accountValidation = (email, password, confirmPassword) => {
    // Checking for invalid email
    const emailRegex = /\w*(\.|-)?\w+@\w*(\.|-)?\w+\.\w*/g;
    // const pswdRegex = new RegExp(ERROR_REGEX.PASSWORD_ERROR);
    const emailValidation = emailRegex.test(email);
    // const passwordValidation = pswdRegex.test(password);

    if (email === "") {
      setPasswordError(null);
      setConfirmPasswordError(null);
      return {
        valid: false,
        error: { msg: "Enter Email Address", onInput: "email" },
      };
    } else if (!emailValidation) {
      setPasswordError(null);
      return {
        valid: emailValidation,
        error: { msg: "Invalid email!", onInput: "email" },
      };
    } else {
      setEmailError(null);
    }

    // Checking for password
    if (password === "") {
      setEmailError(null);
      setConfirmPasswordError(null);
      return {
        valid: false,
        error: { msg: "Enter Password", onInput: "password" },
      };
      // } else if (!passwordValidation){
      //     setEmailError(null);
      //     setConfirmPasswordError(null);
      //     return {valid:passwordValidation,error:{msg:"Invalid password!", onInput:'password'}}
    } else {
      setPasswordError(null);
    }

    // Checking for confirm password

    if (confirmPassword === "") {
      setEmailError(null);
      setPasswordError(null);
      return {
        valid: false,
        error: { msg: "Enter Password Again", onInput: "confirm-password" },
      };
    } else if (confirmPassword !== password) {
      setEmailError(null);
      setPasswordError(null);
      return {
        valid: false,
        error: { msg: "Passwords don't match", onInput: "confirm-password" },
      };
    } else {
      setConfirmPasswordError(null);
    }

    // Passed all test
    return { valid: true, account: { email, password } };
  };

  const clearInput = () => {
    // Clearing the fields
    loggedClientPassword.current.value = "";
    loggedClientEmail.current.value = "";
    loggedClientConfirmPassword.current.value = "";
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const user = {
      email: loggedClientEmail.current.value.toLowerCase(),
      password: loggedClientPassword.current.value,
      confirmPassword: loggedClientConfirmPassword.current.value,
    };
    let data = {
      email: loggedClientEmail.current.value.toLowerCase(),
      password: loggedClientPassword.current.value,
    };

    if (comp_code && comp_code !== "") {
      data = { ...data, company_code: comp_code, url: window.location.href };
    }

    // if user is being redirected from "ai.ez.works" to this webpage
    if (searchParams.get("assignment_id"))
      data = { ...data, assignment_key: searchParams.get("assignment_id") };

    const validation = accountValidation(
      user.email,
      user.password,
      user.confirmPassword,
    ); // validatinng the input

    // for testing data
    // console.log(data);
    // console.log(
    //   API_URL,
    //   history,
    //   setSignUpDisableBtn,
    //   setSignupData,
    //   clearInput,
    //   validation,
    // );

    if (validation.valid) {
      setSignUpDisableBtn(true);
      postData(`${API_URL}/api-client/client/v1/sign-up/`, data)
        .then((res) => {
          if (res.success === true) {
            toast.success("Signed up successfully!");
            if (res?.is_assignment_added) {
              //for redirecion
              const redirectionUrl = `tools/${
                redirectionEnum[res?.is_assignment_added?.tool_name]
              }/${res?.is_assignment_added?.id}`;
              sessionStorage.setItem("redirect", redirectionUrl);
            }
            setSignupData({ email: data.email.toLowerCase() });
            clearInput();
            history("/verifyemail");
            setSignUpDisableBtn(false);
          } else {
            // toast.error(
            //   res?.message || "Error while signing up. Please try again later!"
            // );
            clearInput();
            setEmailError(res.message);
            setSignUpDisableBtn(false);
          }
        })
        .catch((err) => {
          setRetryError(true);
          setSignUpDisableBtn(false);
        });
    } else {
      validation.error.onInput === "email" &&
        setEmailError(validation.error.msg);
      validation.error.onInput === "password" &&
        setPasswordError(validation.error.msg);
      validation.error.onInput === "confirm-password" &&
        setConfirmPasswordError(validation.error.msg);
    }
  };

  const showPassword = (pswd_input) => {
    if (pswd_input.current.type === "password") {
      pswd_input.current.type = "text";
    } else {
      pswd_input.current.type = "password";
    }
  };

  return (
    <form className="common-form client-login-form" onSubmit={handleLogin}>
      <div className="client-form-head">Signup</div>

      <div className="login-input">
        <input
          className="client-input client-form-input"
          ref={loggedClientEmail}
          type="email"
          id="client-email"
          name="client-email"
          placeholder="Work Email"
        />
        {emailError && <InputError errorMsg={emailError} />}
      </div>

      <div className="login-input client-password-input">
        <input
          className="client-input client-form-input"
          ref={loggedClientPassword}
          onChange={(e) => {
            ToolTipError(e, [
              ERROR_REGEX.PASSWORD_ERROR,
              ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
            ]);
          }}
          type="password"
          id="client-password"
          name="client-password"
          placeholder="Password"
        ></input>
        <span
          className="client-pswd-show-btn"
          onClick={(e) => {
            e.preventDefault();
            e.target
              .closest(".client-pswd-show-btn")
              .classList.toggle("client-pswd-show-btn-active");
            showPassword(loggedClientPassword);
            loggedClientPassword.current.focus();
          }}
        >
          <FaEye />
        </span>
        {passwordError && <InputError errorMsg={passwordError} />}
      </div>

      <div className="login-input client-password-input">
        <input
          className="client-input client-form-input"
          ref={loggedClientConfirmPassword}
          onChange={(e) => {
            ToolTipError(e, [
              ERROR_REGEX.PASSWORD_ERROR,
              ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
            ]);
          }}
          type="password"
          id="client-conifrm-password"
          name="client-conifrm-password"
          placeholder="Confirm Password"
        />
        <span
          className="client-pswd-show-btn"
          onClick={(e) => {
            e.preventDefault();
            e.target
              .closest(".client-pswd-show-btn")
              .classList.toggle("client-pswd-show-btn-active");
            showPassword(loggedClientConfirmPassword);
            loggedClientConfirmPassword.current.focus();
          }}
        >
          <FaEye />
        </span>
        {confrimPasswordError && <InputError errorMsg={confrimPasswordError} />}
      </div>

      <div className="client-login-btns-section">
        <div className="client-login-btn-part">
          <Link
            className="client-login-form-btns client-anchor"
            to={
              searchParams.get("assignment_id")
                ? `/?assignment_id=${searchParams.get("assignment_id")}`
                : company_code && company_code_data.includes(company_code)
                ? `/${company_code}`
                : "/"
            }
          >
            Login
          </Link>
          <button
            className="client-login-form-btns client-login-btn"
            type="submit"
            disabled={signUpDisableBtn}
          >
            Signup
          </button>
        </div>
      </div>
      {/* <table className="client-login-bottom-form-btns-table">
                <tbody>
                    <tr>
                        <td><Link className="client-login-form-btns client-anchor" to="/">Login</Link></td>
                        <td><button className="client-login-form-btns client-login-btn" type='submit'>Signup</button></td>
                    </tr>
                </tbody>
            </table> */}
    </form>
  );
};

const ClientVerifyEmail = () => {
  const API_URL = useContext(ApiContext);
  const { signupData } = useContext(SignupDataContext);
  const [sendAgain, setSendAgain] = useState(false);
  const [verifyTime, setVerifyTime] = useState(59);
  const { company_code } = useParams();

  const { setRetryError } = useContext(RetryErrorContext);

  useEffect(() => {
    setRetryError(false);
  }, []);

  const resendVerification = (e) => {
    e.preventDefault();
    postData(`${API_URL}/api-client/auth/v1/email-verify/`, signupData)
      .then((res) => {
        if (res.success === true) {
          setSendAgain(true);
          const timer = setInterval(() => {
            setVerifyTime((prev) => {
              if (prev > 0) {
                return prev - 1;
              } else {
                setSendAgain(false);
                clearInterval(timer);
                return 59;
              }
            });
          }, 1000);
        } else {
          // toast.custom(() => (
          //   <div className="toast-error-container">
          //     <div className="toast-content">{res.message}</div>
          //   </div>
          // ));
          toast.error(res?.message || "Error!");
        }
      })
      .catch((err) => {
        setRetryError(true);
        // toast.custom(() => (
        //   <div className="toast-error-container">
        //     <div className="toast-content">{err.message}</div>
        //   </div>
        // ));
        toast.error(err?.message || "Error!");
      });
  };

  return (
    <div className="client-verify-container">
      <div className="client-form-head">
        Check your inbox for verification email.
      </div>
      <div className="client-login-btns-section">
        <div className="client-login-btn-part">
          {sendAgain ? (
            <span>Send Again in {verifyTime}s</span>
          ) : (
            <span
              className="client-login-form-btns client-anchor"
              onClick={resendVerification}
            >
              Resend Verification
            </span>
          )}
          <Link
            className="client-login-form-btns client-login-btn"
            to={
              company_code && company_code_data.includes(company_code)
                ? `/${company_code}`
                : "/"
            }
          >
            Login
          </Link>
        </div>
      </div>
      {/* <table className="client-login-bottom-form-btns-table">
                <tbody>
                    <tr>
                        <td>
                        { (sendAgain) ? <span>Send Again in {verifyTime}s</span> : <span className="client-login-form-btns client-anchor" onClick={resendVerification}>Resend Verification</span>}
                        </td>
                        <td><Link className="client-login-form-btns client-login-btn" to="/">Login</Link></td>
                    </tr>
                </tbody>
            </table> */}
    </div>
  );
};

export { ClientSignupForm, ClientVerifyEmail };
