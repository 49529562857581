import React, { useEffect, useRef, useState } from "react";
import "./ToolsConsultGptPdfPreview.scss";
import PdfPreview from "./PdfPreview";
import { useDispatch, useSelector } from "react-redux";
import fileNameLimiter from "../../../../../utils/fileNameLimiter";
import { updateFileDownloadLink } from "../../../../../store/consultgptDataSlice/consultgptDataSlice";
import { setModal } from "../../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../../ToolsConstants/TOOLS_MODALS";

const ToolsConsultGptPdfPreview = () => {
  const { file_preview } = useSelector((state) => state.consultGptData);
  const dispatch = useDispatch();
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownIconRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsDownloadDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownIconRef.current &&
        !dropdownIconRef.current.contains(event.target)
      ) {
        setIsDownloadDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownRef, dropdownIconRef]);

  const handleUpdateDownloadLink = async (e, file_id) => {
    e.stopPropagation();
    dispatch(updateFileDownloadLink({ file_id }));
  };

  const handleOpenRequestModal = () => {
    dispatch(setModal(TOOLS_MODALS.NEW_REQ));
  };

  return (
    <section className="tools_consult_files_preview_section">
      <div className="file_preview_container file_source_preview_container">
        <div className="file_preview_head">
          {file_preview?.reference_file_name && (
            <div className="file_preview_heading">
              {fileNameLimiter(file_preview?.reference_file_name)}
            </div>
          )}
          <div className="tool_consult_file_donwload_dropdown">
            <img
              onClick={handleToggleDropdown}
              src={
                process.env.PUBLIC_URL +
                "/resources/consult_file_status_icon/DownloadButton.svg"
              }
              className="tool_consult_file_donwload_img"
              ref={dropdownIconRef}
            />
            {isDownloadDropdownOpen && (
              <div
                className="tool_consult_file_donwload_dropdown_wrapper"
                ref={dropdownRef}
              >
                <span
                  className="tool_consult_file_donwload_dropdown_text"
                  onClick={(e) => {
                    handleUpdateDownloadLink(
                      e,
                      file_preview?.reference_file_id,
                    );
                  }}
                >
                  Download PDF
                </span>
                <span
                  className="tool_consult_file_donwload_dropdown_text"
                  onClick={(e) => {
                    handleUpdateDownloadLink(
                      e,
                      file_preview?.reference_file_id,
                    );
                  }}
                >
                  Download Original
                </span>
                <div
                  className="tool_consult_file_donwload_dropdown_text"
                  onClick={handleOpenRequestModal}
                >
                  Translate File
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="file_preview">
          <PdfPreview />
        </div>
      </div>
    </section>
  );
};

export default ToolsConsultGptPdfPreview;
